var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:"ft",attrs:{"text":"Lubes"}})],1),_c('div',{staticClass:"buttonText ml-1",attrs:{"id":"result"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],staticClass:"ml-2 mt-3 white--text ft",staticStyle:{"font-size":"12px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.open('add')}}},[_vm._v(" New Lube"),_c('v-icon',{attrs:{"size":"20","color":"white"}},[_vm._v("mdi-plus")])],1),_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],staticClass:"ml-2 mt-3 white--text ft",staticStyle:{"font-size":"12px"},attrs:{"color":"primary","to":{ name: 'master.lubes.opening.stock' }}},[_vm._v(" Add Lubes Opening Stock ")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-3 mr-3",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.getLubes()}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])]}}])},[_c('span',{staticClass:" ft font-weight-medium font-size-sm"},[_vm._v("Refresh Page")])])],1),_c('h4',{staticClass:"blue-grey--text  ft font-size-sm",staticStyle:{"margin-top":"20px","margin-left":"10px"}},[_vm._v(" List of Lubes ")])]),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('div',{attrs:{"id":"lubeList"}},[_c('v-data-table',{staticClass:"font-weight-medium box-shadow-light ft",attrs:{"headers":_vm.headers,"items":_vm.lubes,"item-key":"product","loading-text":"Loading... Please wait","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"margin-right":"-80px"},attrs:{"color":"blue-grey","size":"25"}},[_c('span',{staticClass:"white--text subtitle-1 "},[_vm._v(_vm._s(_vm._f("firstLetterFunction")(item.name)))])])]}},{key:"item.pumpProductId",fn:function(ref){
var item = ref.item;
return _vm._l((item.pumpProductId),function(product){return _c('v-chip',{key:product.id,staticClass:"ma-1",attrs:{"label":"","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(product.productId.name))])])})}},{key:"item.pack",fn:function(ref){
var item = ref.item;
return [(item.lubeId && item.lubeId.length > 0)?_c('span',[_vm._v(" "+_vm._s(item.lubeId[0].pack)+" ")]):_vm._e()]}},{key:"item.retailCost",fn:function(ref){
var item = ref.item;
return [(item.lubeId && item.lubeId.length > 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(item.lubeId[0].retailCost)))]):_vm._e()]}},{key:"item.sellPriceToCustomer",fn:function(ref){
var item = ref.item;
return [(item.lubeId && item.lubeId.length > 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(item.lubeId[0].sellPriceToCustomers)))]):_vm._e()]}},{key:"item.retailCostPerUnit",fn:function(ref){
var item = ref.item;
return [(item.lubeId && item.lubeId.length > 0)?_c('span',[_vm._v(_vm._s(_vm._f("currencyFormat")(item.lubeId[0].retailCostPerUnit)))]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("timestamp")(item.createdAt,"DD/MM/YYYY"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","tag":"a","link":"","dark":"","small":""},on:{"click":function($event){return _vm.open('edit', item)}}},on),[_c('v-icon',{attrs:{"color":"blue-grey","small":""}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Lube")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","tag":"a"},on:{"click":function($event){_vm.open('details', item),
                        _vm.$store.dispatch(
                          'products/newlyAddedProductBranches',
                          item.id
                        )}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-md"},[_vm._v("sanitizer")])])]}}],null,true)},[_c('span',[_vm._v("Add Opening Stock Qty")])])]}}])})],1)]],2)],1),_c('AddLubeDialog',{attrs:{"state":_vm.dialog('add'),"loading":_vm.loading},on:{"actions":_vm.close,"lube":_vm.addLube}}),_c('EditLubeDialog',{attrs:{"state":_vm.dialog('edit'),"details":_vm.details,"loading":_vm.loading},on:{"actions":_vm.close,"lube":_vm.editLube}}),_c('AddOpeningStockDialog',{attrs:{"state":_vm.dialog('details'),"allBranches":_vm.branches,"details":_vm.details,"loading":_vm.isBtnLoading,"existingBranches":_vm.newlyAddedProductExistingBranches},on:{"actions":_vm.close}}),_c('v-snackbar',{attrs:{"timeout":4000,"top":"","right":"","dense":"","color":_vm.status || _vm.SnackBarStatus},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.message || _vm.SnackBarMessage))]),_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.showSnackBar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }