<template>
  <v-container>
    <div class="ml-2"><PageTitle text="Lubes" class="ft" /></div>
    <div class="buttonText ml-1" id="result">
      <div class="d-flex flex-row flex-grow-1">
        <div class="d-flex flex-row flex-grow-1">
          <v-btn
            v-ripple="{ class: 'primary--text' }"
            color="primary"
            class="ml-2 mt-3 white--text ft"
            style="font-size: 12px"
            @click="open('add')"
          >
            New Lube<v-icon size="20" color="white">mdi-plus</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            v-ripple="{ class: 'primary--text' }"
            class="ml-2 mt-3 white--text ft"
            style="font-size: 12px"
            :to="{ name: 'master.lubes.opening.stock' }"
          >
            Add Lubes Opening Stock
          </v-btn>
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="getLubes()"
              icon
              color="primary"
              class="mt-3 mr-3"
            >
              <i class="material-icons-outlined">refresh</i>
            </v-btn>
          </template>
          <span class=" ft font-weight-medium font-size-sm">Refresh Page</span>
        </v-tooltip>
      </div>

      <h4
        style="margin-top: 20px; margin-left: 10px"
        class="blue-grey--text  ft font-size-sm"
      >
        List of Lubes
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div id="lubeList">
            <v-data-table
              :headers="headers"
              :items="lubes"
              item-key="product"
              loading-text="Loading... Please wait"
              :loading="loading"
              class="font-weight-medium box-shadow-light ft"
            >
              <template #item.avatar="{item}">
                <v-avatar
                  color="blue-grey"
                  style="margin-right: -80px"
                  size="25"
                >
                  <span class="white--text subtitle-1 ">{{
                    item.name | firstLetterFunction
                  }}</span>
                </v-avatar>
              </template>
              <template #item.pumpProductId="{item}">
                <v-chip
                  label
                  small
                  class="ma-1"
                  v-for="product in item.pumpProductId"
                  :key="product.id"
                >
                  <span class="font-weight-bold">{{
                    product.productId.name
                  }}</span>
                </v-chip>
              </template>
              <template #item.pack="{item}">
                <span v-if="item.lubeId && item.lubeId.length > 0">
                  {{ item.lubeId[0].pack }}
                </span>
              </template>
              <template #item.retailCost="{item}">
                <span v-if="item.lubeId && item.lubeId.length > 0">{{
                  item.lubeId[0].retailCost | currencyFormat
                }}</span>
              </template>
              <template #item.sellPriceToCustomer="{item}">
                <span v-if="item.lubeId && item.lubeId.length > 0">{{
                  item.lubeId[0].sellPriceToCustomers | currencyFormat
                }}</span>
              </template>

              <template #item.retailCostPerUnit="{item}">
                <span v-if="item.lubeId && item.lubeId.length > 0">{{
                  item.lubeId[0].retailCostPerUnit | currencyFormat
                }}</span>
              </template>
              <template #item.createdAt="{item}">
                {{ item.createdAt | timestamp("DD/MM/YYYY") }}
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      tag="a"
                      link
                      @click="open('edit', item)"
                      dark
                      small
                      v-on="on"
                    >
                      <v-icon color="blue-grey" small
                        >mdi-pencil-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Edit Lube</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="
                        open('details', item),
                          $store.dispatch(
                            'products/newlyAddedProductBranches',
                            item.id
                          )
                      "
                      small
                      icon
                      tag="a"
                      v-on="on"
                    >
                      <i class="material-icons-outlined font-size-md"
                        >sanitizer</i
                      >
                    </v-btn>
                  </template>
                  <span>Add Opening Stock Qty</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col>
    </v-row>

    <AddLubeDialog
      :state="dialog('add')"
      @actions="close"
      @lube="addLube"
      :loading="loading"
    />
    <EditLubeDialog
      :state="dialog('edit')"
      @actions="close"
      @lube="editLube"
      :details="details"
      :loading="loading"
    />

    <AddOpeningStockDialog
      :state="dialog('details')"
      :allBranches="branches"
      :details="details"
      :loading="isBtnLoading"
      @actions="close"
      :existingBranches="newlyAddedProductExistingBranches"
    />

    <v-snackbar
      v-model="showSnackBar"
      :timeout="4000"
      top
      right
      dense
      :color="status || SnackBarStatus"
    >
      <span class="white--text">{{ message || SnackBarMessage }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/PageTitle";
import AddLubeDialog from "../../pages/lubes/dialogs/AddLubeDialog";
import { timestamp } from "../../../filters/timestamp";
import { currencyFormat } from "../../../filters/currencyFormat";
import WidgetMixins from "../../../mixins/WidgetMixins";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "List",
  components: {
    PageTitle,
    AddLubeDialog,
    EditLubeDialog: () => import(`../../pages/lubes/dialogs/EditLubeDialog`),
    AddOpeningStockDialog: () =>
      import(`../../shared/dialogs/AddOpeningStockDialog`)
  },
  data() {
    return {
      lubes: [],
      loading: false,
      showSnackBar: false,
      status: "",
      message: "",
      dialogState: {
        add: false,
        edit: false,
        delete: false,
        details: false
      },
      details: null
    };
  },
  filters: {
    timestamp,
    currencyFormat
  },
  computed: {
    ...mapGetters({
      // all these are from store
      // TODO refactor this to move everything to vuex
      isBtnLoading: "getIsLoading",
      SnackBarState: "getSnackBarState",
      SnackBarMessage: "getSnackBarMessage",
      SnackBarStatus: "getSnackBarStatus",
      DialogState: "getDialogState",
      branches: "branches/getAllBranches",
      newlyAddedProductExistingBranches: "products/getNewlyAddedProductBranches"
    }),
    headers() {
      return [
        {
          text: "",
          value: "",
          filterable: false,
          sortable: false
        },
        {
          text: "Name",
          align: "start",
          // sortable: false,
          value: "name"
        },
        {
          text: "Pack",
          value: "pack"
        },
        { text: "Retail Cost", value: "retailCost" },
        { text: "Retail Cost/Unit", value: "retailCostPerUnit" },
        { text: "Selling Price To Customer", value: "sellPriceToCustomer" },
        { text: "Actions", value: "actions" }
      ];
    }
  },
  mixins: [WidgetMixins],
  methods: {
    async addLube(payload) {
      try {
        this.loading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_BASE}/products/lubes/create`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.showSnackBar = true;
          this.message = "Lube added successfully";
          this.status = "success";
          this.loading = false;
          this.close("add");
          this.getLubes();
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get lubes list";
        this.status = "red";
        this.loading = false;
      }
    },
    async getLubeDetails(id) {
      try {
        this.loading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE}/products/lubes/${id}/details`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.loading = false;
          this.details = response.data;
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get lubes list";
        this.status = "red";
        this.loading = false;
      }
    },
    async editLube(payload) {
      try {
        this.loading = true;
        const response = await axios.put(
          `${process.env.VUE_APP_BASE}/products/lubes/${payload.id}/edit`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.close("edit");
          this.showSnackBar = true;
          this.message = "Lube updated successfully";
          this.status = "success";
          this.loading = false;
          this.getLubes();
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get lubes list";
        this.status = "red";
        this.loading = false;
      }
    },
    async getLubes() {
      try {
        this.loading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE}/products/lubes/list`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.loading = false;
          this.lubes = response.data;
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get lubes list";
        this.status = "red";
        this.loading = false;
      }
    },
    async downloadPDF() {}
  },
  watch: {
    SnackBarState(value) {
      this.showSnackBar = value;
    },
    DialogState(value) {
      console.log(value);
    }
  },

  created() {
    this.getLubes();
    this.$store.dispatch("branches/list");
  }
};
</script>
