<template>
  <v-dialog
    overlay-color="rgba(0,0,0,0.4)"
    overlay-opacity="0.8"
    v-model="state"
    max-width="520"
    persistent
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(addLube)" class="px-3">
        <v-card flat ref="form">
          <v-card-title>
            <span class="font-weight-bold blue-grey--text ml-1">Add Lube</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    autofocus
                    v-model="lube.name"
                    label="Name"
                    :error-messages="errors[0]"
                    hint="Name of the lube"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required|numeric"
                >
                  <v-text-field
                    outlined
                    :error-messages="errors[0]"
                    v-model="lube.pack"
                    label="Product Pack"
                    hint="Add the lubes quantity in packs"
                  />
                </ValidationProvider>
              </v-row>

              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    :error-messages="errors[0]"
                    outlined
                    v-model="lube.retailCostPerUnit"
                    label="Retail Cost Per Unit"
                    hint="Add retail cost per unit"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    :error-messages="errors[0]"
                    v-model="lube.sellingPriceToCustomers"
                    label="Selling Price To Customers"
                    hint="Add selling price to customers"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-row>
              <!-- <v-row>
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-row flex-grow-1"
                    v-slot="{ errors }"
                    rules="numeric"
                  >
                    <v-text-field
                      outlined
                      :error-messages="errors[0]"
                      v-model="lube.minimumThreshold"
                      label="Minimum Lubes Threshold"
                      hint="Mimum threshold  for the products"
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-row> -->
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="mr-2 ft text-capitalize" text @click="close">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              type="submit"
              class="ft white--text text-capitalize"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
export default {
  name: "AddLubeDialog",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    state: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    lube: {
      name: "",
      retailCost: "",
      pack: "",
      retailCostPerUnit: "",
      sellingPriceToCustomers: "",
      minimumThreshold: 0
    },
    reset: {
      name: " ",
      retailCost: "",
      pack: null,
      retailCostPerUnit: null,
      sellingPriceToCustomers: null,
      minimumThreshold: 0
    }
  }),
  watch: {
    loading(value) {
      if (!value) {
        this.lube = { ...this.reset };
      }
    }
  },
  methods: {
    close() {
      this.$emit("actions", "add");
    },
    addLube() {
      this.$emit("lube", this.lube);
    }
  },
  created() {}
};
</script>
